<template>
<div class="cabinet__content contacts">
    <h2 class="cabinet__header d-none d-md-block">Контактная информация</h2>
    <form @submit.prevent="submitHandler">
        <div class="row">
            <div class="col-md-6 mb-4 pb-2">

                <label for="phone">Номер телефона</label>
                <the-mask mask="F (EEE) EEE EE EE" :tokens="hexTokens" placeholder="7 (XXX) XXX XX XX" v-model.trim="$v.phone.$model" class="form-control" id="phone" />
                <div class="invalid-feedback d-block" v-if="($v.phone.$dirty && !$v.phone.required) || ($v.phone.$dirty && !$v.phone.minLength)">Введите номер телефона</div>
            </div>
            <div class="col-md-6 mb-4 pb-2">
                <label for="name">Имя</label>
                <input type="text" id="name" class="form-control" v-model.trim="$v.name.$model">
                <div class="invalid-feedback d-block" v-if="$v.name.$dirty && !$v.name.required">Введите имя</div>
            </div>
            <div class="col-md-6 mb-4 pb-2">
                <label for="email">E-mail</label>
                <input type="text" id="email" class="form-control" v-model.trim="$v.email.$model">
                <div class="invalid-feedback d-block" v-if="($v.email.$dirty && !$v.email.required) ||($v.email.$dirty && !$v.email.email)">Некорректный e-mail</div>
            </div>
            <div class="col-md-6 mb-4 pb-2">
                <label for="dob">День рождения</label>
                 <VueDatePicker v-model="dob" class="dayOfBirth"   format="DD.MM.YYYY" min-date="1940" :max-date="maxDate" placeholder="Выбрать дату" no-header/>
                <!-- <input type="text" id="dob" class="form-control"> -->
                <!-- <the-mask mask="FF.FF.FFFF" :tokens="dobTokens" placeholder="ДД.ММ.ГГГГ" v-model.trim="$v.dob.$model" class="form-control" id="phone" /> -->
                <div class="invalid-feedback d-block" v-if="($v.dob.$dirty && !$v.dob.required) || ($v.dob.$dirty && !$v.dob.minLength)">Введите дату рождения</div>
            </div>
            <div class="col-md-12 mt-2 mb-4 contacts__btn">
                <button class="btn-oval" type="submit">Сохранить изменения</button>
            </div>
        </div>
    </form>
</div>
</template>

<script>
// import Navbar from '@/components/Navbar.vue'
// import Footer from '@/components/Footer.vue'
// import NavbarVertical from '@/components/NavbarVertical.vue'
import {
    TheMask
} from "vue-the-mask";
import {
    required,
    email,
    minLength
} from 'vuelidate/lib/validators'
import _ from 'lodash'
const date = new Date();
export default {
    
    name: "Cart",
     head: {
        title: {
            inner: 'Контактная информация',
            separator: '-',
            complement: 'Ели Млели'
        },
    },
    components: {
        TheMask,
    },
    
    data() {
        return {
             date,
              maxDate: `${date.getFullYear()-18}-${date.getMonth()}-${date.getDate()}`,
            hexTokens: {
                F: {
                    pattern: /[7-8]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },
                E: {
                    pattern: /[0-9]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },
            },
            // dobTokens: {
            //     F: {
            //         pattern: /[0-9]/,
            //         transform: (v) => v.toLocaleUpperCase(),
            //     },

            // },
            phone: "",
            name: "",
            email: "",
            dob: null,
        };
    },
    validations: {
        name: {
            required,
        },
        email: {
            required,
            email,
        },
        phone: {
            required,
            minLength: minLength(11)
        },
        dob: {
            required,
            minLength: minLength(8)
        },
    },
    async mounted() {
        await this.$store.dispatch("actionGetInfo");
        let phone = this.$store.state.cabinet.info.phone,
            name = this.$store.state.cabinet.info.name,
            email = this.$store.state.cabinet.info.email,
            dob = this.$store.state.cabinet.info.birthday;
        this.phone = phone
        this.name = name
        this.email = email 
         this.dob = dob
   
    },
    methods: {
        submitHandler: _.throttle(async function () {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            const data = {
                email: this.email,
                name: this.name,
                birthday: this.dob
            }
           // const today = new Date(this.$moment('2005-01-01').format('YYYY-MM-DD'));
           // const birthday = new Date(this.$moment(data.birthday).format('YYYY-MM-DD'));
          //  const birthdayMin = new Date(this.$moment('1934-01-01').format('YYYY-MM-DD'))

            // if (+today > +birthday & +birthday > +birthdayMin) {
                await this.$store.dispatch("actionUpdateInfo", data);
                this.$toast.success("Изменения сохранены");
            // } else {
            //     this.$toast.error("Некорректная дата рождения");
            // }

        }, 7000),

    },

}
</script>

<style lang="scss" scoped>
.cabinet {}

.container {}

.cabinet {
    margin-top: 85px;
    padding-bottom: 270px;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
        padding-bottom: 110px;
        margin-top: 25px;
        flex-direction: column;
    }

    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        //background: #FFFFFF !important;
        box-shadow: -10px 6px 23px rgba(156, 156, 156, 0.1);
        border-radius: 12px;
        padding: 40px 20px 0px 49px;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            padding-top: 20px;
        }

        .row {
            max-width: 700px;
        }
    }

    &__header {
        margin-bottom: 29px;
        font-size: 24px;
    }
}

.contacts {
    background: url(../assets/contacts-inner.svg) 100% -5% no-repeat #fff;
    padding-bottom: 35px;
    height: fit-content;

    @include media-breakpoint-down(md) {
        background: #fff;

    }

    label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        padding-left: 30px;
        margin-bottom: 5px;

        @include media-breakpoint-down(md) {
            text-align: center;
            width: 100%;
            padding-left: 0;
        }
    }

    input {
        border: none;
        border-radius: 73px;
        height: 57px;
        font-family: Montserrat;
        color: #000000;
        padding-left: 29px;
        font-weight: 600;
        background: #F5F5F5 !important;
        font-size: 16px;
        //margin-bottom: 35px;

        @include media-breakpoint-down(md) {
            margin-bottom: 18px;
        }
    }

    &__btn {
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            margin-bottom: 35px;

            button {
                width: 100%;
            }
        }
    }
}

#app>div {

    background: url(../assets/contacts-outer.svg) 92% 70% no-repeat #F5F5F5;

    @include media-breakpoint-down(md) {
        background-size: contain;
    }
}

.footer {
    background: #fff;
}

.top-navbar {
    background: #fff !important;
}

.invalid-feedback {
    padding-left: 30px;
}
.vd-wrapper {
    max-width: 100%;
    margin-left: 0;
    margin-right: auto;
      background: #F5F5F5;
      border-radius: 73px;
      height: 57px !important;

}
.cabinet{
    input{
           height: 57px !important;
        background: #F5F5F5;
        border-color: red !important;
        
    }
}
</style>
